let writing = [
    {
        src: "https://www.linkedin.com/pulse/china-second-space-race-shahzoda-davlatova/",
        title: "China and the Second Space Race",
        desc: "The hype is real. China has entered the ballpark of big space-nations, and it’s causing a commotion. Major media outlets such as Popular Science, CNN, the Guardian, and even Forbes have been buzzing about China’s presence in space... ",
    },
    {
        src: "https://www.linkedin.com/pulse/art-vr-shahzoda-davlatova/",
        title: "The Art of VR",
        desc: "This article explores the head mounted industry as it currently stands, by looking at how it can revolutionize different fields, problems facing each field, and some creatives solutions already made, along with a few other snippets.",
    },
    {
        src: "https://dev.to/shahzzoda/offers-from-amazon-apple-and-facebook-things-i-wish-i-knew-3hgd",
        title: "Offers from Amazon, Apple, and Facebook: Things I Wish I Knew.",
        desc: "This article is about how I approached interviewing, and the information + tips contained here are simply my opinions formed through various experiences. As with all advice on the internet, take it with a grain of salt.",
    },
    // {
    //     src: "https://us6.campaign-archive.com/?u=e43afe7f1fde9f65ad8af7c9b&id=d5d6e07395",
    //     title: "New Season, New Me",
    //     desc: "There's a two big differences I made this season. 1. Asked for help! I've finally started to bring in more people. I'll be having Rohma as a regular cohost & my friend Chris will be doing the editing more often!",
    // },
];

export default writing;