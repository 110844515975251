import { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';


function Nav() {
  const [image, setImage] = useState(1);
  const [imgLink, setImgLink] = useState("./img/plant/1.jpg");

  useEffect(() => {
    const interval = setInterval(() => {
      setImage(image + 1 > 23 ? 1 : image + 1);
      setImgLink("./img/plant/" + String(image + 1) + ".jpg");
    }, 20000); // runs every 20 seconds
    return () => clearInterval(interval);
  }, [image, imgLink]);

  return (
    <div className="root">
      <div className="d-flex align-items-start">
        <div className="nav flex-column nav-pills me-3" id="tab" role="tablist" aria-orientation="vertical">
          <Link to="/">
            <div className="nav-link active" id="shahzoda-tab" data-bs-toggle="pill" data-bs-target="#shahzoda" type="div" role="tab" aria-controls="shahzoda" aria-selected="true">
              Shahzoda
            </div>
          </Link>
          <Link to="/working">
            <div className="nav-link" id="working-tab" data-bs-toggle="pill" data-bs-target="#working" type="div" role="tab" aria-controls="working" aria-selected="false">
              Experience
            </div>
          </Link>
          <Link to="/orgs">
            <div className="nav-link" id="writing-tab" data-bs-toggle="pill" data-bs-target="#writing" type="div" role="tab" aria-controls="writing" aria-selected="false">
              Honors
            </div>
            <Link to="/building">
              <div className="nav-link" id="building-tab" data-bs-toggle="pill" data-bs-target="#building" type="div" role="tab" aria-controls="building" aria-selected="false">
                Projects
              </div>
            </Link>
          </Link>
          <Link to="/writing">
            <div className="nav-link" id="writing-tab" data-bs-toggle="pill" data-bs-target="#writing" type="div" role="tab" aria-controls="writing" aria-selected="false">
              Writing
            </div>
          </Link>
        </div>
        <div className="tab-content" id="tabContent">
          <Outlet />
        </div>
        <span className='plant-baby'>
          <img src={imgLink} />
        </span>
      </div>
    </div>
  );
}

export default Nav;
