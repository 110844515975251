import sillyappy from "./appa.png";

function TooSmall() {
    return <div className="container-fluid" style={{ "text-align": "center" }}>
        <p>
            much like a good meal, this website needs to be enjoyed while
            sitting down in front of a big screen. this website is anti-mobile.
            go enjoy the moment. it'll be here when you're back.
        </p>
        <img src={sillyappy} style={{ "max-width": "100vw", "padding": "50px" }} />
        <p>-zoda</p>
    </div>
}

export default TooSmall;