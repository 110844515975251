let internships = [
    {
        img: "./img/internships/1.png",
        title: "Apple Internship",
        desc: "Interned at Apple the first summer after the pandemic from home, Summer 2020. I was a part of the Apple Watch team and I got to do some really cool real time audio manipulation in Objective C and C++.",
    },
    {
        img: "./img/internships/2.png",
        title: "Facebook",
        desc: "Now called Meta, I interned here the fall right after Apple, working on privacy enforcing technology in the Reality Labs. Practically all of my work was in python around parsing.",
    },
    {
        img: "./img/internships/3.png",
        title: "Teachers Pay Teachers",
        desc: "Interned twice here. Once during the winter, and once during the summer! DevOps and Mobile Development. Learned so much and had a great time doing so!",
    },
    {
        img: "./img/internships/4.png",
        title: "JP Morgan Chase",
        desc: "Interned here twice, 3 months each. Full time during the summer, and part time during the fall. Building a full stack web app during the summer and enhancing internal tooling around efficiency during the fall.",
    },
    {
        img: "./img/internships/5.png",
        title: "Brooklyn Bark",
        desc: "First internship was at Brooklyn Bark! Was hired part time during the summer, and came back during the fall. I did mainly frontend (CSS/SCSS) and some PHP! Worked on a Wordpress site with a designer and a SR engineer with full ownership on the site!",
    },
];

export default internships;