import internships from "./internships.js";
import Organized from "./Organized/Organized.jsx"

function Working() {
    return (
        <div className="internships container">
            <div className="row">
                <h2>Internships</h2>
                <p>
                    I currently work at Meta, in Reality Labs on Smartglasses and AI.
                    Here are some of the previous places I've interned. You can click
                    on these for a tldr of my experiences at these companies or head to
                    my <a href="https://www.linkedin.com/in/shahzodadavlatova">LinkedIn</a> for
                    a more detailed view on my contributions.
                </p>
            </div>
            <div className="row">
                {
                    internships.map(internship =>
                        <div className="col-2" data-bs-toggle="modal" data-bs-target={"#" + internship.img + "Modal"} key={internship.img}>
                            <img className="internship-pic img-fluid m-3" src={internship.img} alt="" />
                            <div className="modal" id={internship.img + "Modal"} tabIndex="-1" aria-labelledby={internship.img + "ModalLabel"} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <h1 className="modal-title fs-5" id={internship.img + "ModalLabel"}>{internship.title}</h1>
                                        <div className="modal-body">
                                            <p>{internship.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            <Organized />
        </div>
    );
};

export default Working;
