let organized = [
    {
        img: "./img/organized/1.png",
        title: "Hack Brooklyn, Co-President",
        desc: "Brooklyn College's first hackathon! Raised over 15k, had over 300 participants, and lead 12 other people to make this happen. We had great positive feedback, despite having to turn this fully remote in 3 weeks. ",
    },
    {
        img: "./img/organized/2.png",
        title: "Brooklyn College Computer Science Club, President",
        desc: "Hosted around 3 events every month focusing on improving recruitment and student body engagement with the larger tech echo system. Organized company visits, hackathon attendance, technical talks, office hours, and plenty of other events. ",
    },
    {
        img: "./img/organized/3.png",
        title: "WiCS Mentorship Initiative, Director",
        desc: "Was Co-Director of the Women in Computer Science Mentorship Initiative that connects students with mentors in computer science and IT to build a community of supportive women in tech. I did all aspects of pairing, training, event hosting, and recruiting.",
    },
    {
        img: "./img/organized/4.png",
        title: "Project Rousseau, Lead Instructor",
        desc: "Was a physics instructor for high school students in underserved communities. I held weekly classes to teach, discuss, practice and demonstrate relevant topics and reviewed past four regents (2012-2016) to compile related questions for every meeting.",
    },
];

export default organized;