import organized from "./organized.js";

function Volunteering() {
    return (
        <span>
            <div className="row">
                <h2>Organized</h2>
                <p>
                    Every once in a while, I find ways to bring people together.
                    This passion started as a way to empower my community at
                    Brooklyn College which I believed to be really talented yet underserved.
                    Click on these icons to learn more! You can also learn more
                    on my <a href="https://www.linkedin.com/in/shahzodadavlatova">LinkedIn</a>!
                </p>
            </div>
            <div className="row">
                {
                    organized.map(internship =>
                        <div className="col-2" data-bs-toggle="modal" data-bs-target={"#" + internship.img + "Modals"} key={internship.img}>
                            <img className="internship-pic img-fluid m-3" src={internship.img} alt="" />
                            <div className="modal" id={internship.img + "Modals"} tabIndex="-1" aria-labelledby={internship.img + "ModalsLabel"} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <h1 className="modal-title fs-5" id={internship.img + "ModalsLabel"}>{internship.title}</h1>
                                        <div className="modal-body">
                                            <p>{internship.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            {/* 
            <div className="row">
                {
                    organized.map(volunteer =>
                        <div className="section text-start">
                            <h3>{volunteer.title}</h3>
                            <p>{volunteer.desc}</p>
                            <hr />
                        </div>
                    )}
            </div> */}
        </span>
    );
};

export default Volunteering;
