import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import './index.css';
import Nav from './Navbar';
import Shahzoda from './Shahzoda/Shahzoda';
import Working from './Experience/Working';
import Writing from './Writing/Writing.jsx';
import Building from './Building/Building';
import Honors from './Experience/Honors/Honors';
import TooSmall from './TooSmall';
import "./Main.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        path: "/",
        element: <Nav />,
        children: [
            {
                path: "/",
                element: <Shahzoda />,
            },
            {
                path: "/working",
                element: <Working />,
            },
            {
                path: "/building",
                element: <Building />,
            },
            {
                path: "/orgs",
                element: <Honors />,
            },
            {
                path: "/writing",
                element: <Writing />,
            },
        ],
    },
]);

/* Storing user's device details in a variable*/
let details = navigator.userAgent;
let regexp = /android|iphone/i;
let isMobileDevice = regexp.test(details);
console.log(details);
if (window.innerWidth < 700 || isMobileDevice) {
    root.render(
        <React.StrictMode>
            <TooSmall />
        </React.StrictMode>
    )
}
else {
    root.render(
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}

