// import zoda from "./zoda-2.jpeg"
import { useState } from "react";

function Shahzoda() {
    const possibleMes = [
        " Shahzoda",
        " zoda",
        " a life romanticizer.",
        " an engineer.",
        " a creator.",
        " a dreamer.",
        " a reader.",
        " a music lover.",
        " a terrible dancer.",
        " an ATLA kid.",
        " a virgo.",
        " sometimes funny.",
        " a social introvert.",
        " inspired easily.",
        " endlessly curious.",
        " the type to work on a project for years on end, even with large gaps.",
        " the type to fixate on one thing at a time.",
        " often called stubborn.",
        " an older sister of 3 lovely lil kids <3",
        " from Uzbekistan and raised in Brooklyn.",
        " a proud product of public education.",
        " ambitious.",
        " guided by principles I choose consciously",
        " deeply spiritual, but not religious.",
        " grateful for friendships, good outfits, fresh pastries, and the smell of winter."
    ];
    const [i, setI] = useState(1);
    const [me, setMe] = useState(possibleMes[0]);

    return <div className="container">
        <div className="row">
            <div className="col">
                <h1>Hello, I'm <span className="zoda-labels" onClick={
                    () => {
                        // let index = Math.round(Math.random() * (possibleMes.length - 1));
                        setI(i + 1 >= possibleMes.length ? 0 : i + 1);
                        setMe(possibleMes[i])
                    }}>{me}</span></h1>
                <p>
                    Lately, I've been thinking a lot about Amor Fati. I was reading
                    the Unbearable Lightness of Being by Milan Kundera where he lightly
                    touches upon the concept of Eternal Return by Friedrich Nietzsche. It
                    means the love of life. Love of life and fate through everything; love,
                    loss, suffering, pain, glory, and the mundane. What a rebellious way to
                    live life.
                </p>
                <p>
                    I was born in Uzbekistan, grew up in Brooklyn, and now making
                    a home out of SF. I work on Smartglasses and AI at Meta. I spend time meeting new
                    people, exploring, reading, sipping coffee, playing chess,
                    scrolling <a href="https://twitter.com/zodattack" target="_blank">twitter</a>, and building.
                </p>
                <p>
                    Expressing oneself is hard. It's always been especially hard for me
                    to do this on the internet. With safe buffer of the screen and the
                    ability to control exactly what I choose to display, it should be
                    easier, but it's not. We're all so many things at once. No single
                    label fits us well at every moment. I archived a bunch of random
                    things I thought might give a glimpse to some sides of me here. This
                    website was a labor of love and a moment of public introspection. I've
                    added a bunch of little easter eggs in it. I hope you enjoy exploring this
                    site at least as much as I have enjoyed building it.
                </p>
                <p>
                    I studied Computational Math and Physics at Brooklyn College.
                    Brooklyn College is part of the public city university of New York (CUNY).
                    Every company I wanted to work in was 30 min train ride, yet felt so
                    out of reach. I didn't know anyone who got very far from my school and
                    that motivated me a lot. They say spite and charm can get you very far.
                    I did 8 internships, volunteered, ran a lot of clubs, started
                    a hackathon, and got multiple scholarships. You can learn more about this, if
                    you'd like to.
                </p>
                <p>
                    Creation is a big value of mine. I want to start focusing on that even more.
                    I spent last year getting settled in SF, and before that getting out to SF. It
                    was a rough year, but I'm so hopeful of this year. We'll see how it goes.
                    I have made a bunch of friends, and started a little chess club here, but I want
                    to create a community centered around tech, creation, and sharing knowledge.
                </p>
                <p>
                    If anything here resonates with you, shoot me a message. Let's grab
                    coffee or go for a walk.
                </p>
            </div>
            <div className="row">
                <div id="button-div" className="col">
                    <a
                        id="typeform"
                        type="button"
                        href="https://shahzoda2.typeform.com/to/WFBA5UdT" >
                        say hi~
                    </a>
                </div>
            </div>
        </div>
    </div >

};

export default Shahzoda;