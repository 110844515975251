import affiliations from "./affiliations";

function Honors() {
    return (<div className="container">
        <div className="row">
            <h2>Affiliations and Honors</h2>
            <p>
                Here's some of the organizations that have recognized me and communities
                I was officially a part of:
            </p>
        </div>
        <div className="row">
            {
                affiliations.map(aff =>
                    <div key={aff.key}>
                        <h3><a href={aff.src}>{aff.title}</a></h3>
                        <p>{aff.desc}</p>
                        <hr />
                    </div>
                )}
        </div>
    </div>);
};

export default Honors;