let affiliations = [
    {
        key: "asd",
        src: "https://buildyourfuture.withgoogle.com/scholarships/generation-google-scholarship",
        title: "Google Women Techmakers for CS",
        desc: "Now called Generation Google, Google offers this scholarship to 10 students each year out of all of North America for computer science! I was fortunate enough to be granted this scholarship and have some of my financial worries relieved!! Typically, they fly the scholars out to MPK campus and offer an in person experience, but I won this right at the onset of the pandemic, so I never got to make too many connections from this! It was still a great honor and I recall getting the email very vividly.",
    },
    {
        key: "dfg",
        src: "https://cunytechprep.org/",
        title: "CUNY Tech Prep",
        desc: "A year long part time bootcamp where 125 students are selected to learn full stack development! I learned so much from Edgardo, Jacky, and all the career coaches here. I was also fortunate enough to be allowed to participate in the pilot data science course! I met some of the hardest working CUNY students here, I still keep in touch with a few of them and consider them my closest friends.",
    },
    {
        key: "hjdk",
        src: "https://www.linkedin.com/posts/meta_this-fall-were-hosting-our-above-beyond-activity-6696505005505040384-rHvv/?trk=public_profile_like_view",
        title: "Facebook Above & Beyond CS Student",
        desc: "Meta (at the time, called Facebook) offered a 13 week program that connected me with computer science students of my background and prepared us for the interview season ahead with classes, homework, and mock interviews! It included a company tour, new connections, and behavioral skill training! It's historically been a survey monkey link that expires after applications are closed, so I cant provide a consistent link for this! I linked the company's LinkedIn post since that seems to be one way to stay in touch with the application process.",
    },
    {
        key: "hjk",
        src: "https://www.linkedin.com/posts/meta_this-fall-were-hosting-our-above-beyond-activity-6696505005505040384-rHvv/?trk=public_profile_like_view",
        title: "Rewriting The Code Fellowship",
        desc: "RTC offered a vibrant network of engineers, alums and mentors for me! It included an intimate cohort of students and two mentors to navigate summer internships and yearly support with informational events and networking opportunities with different companies. Though I did occasionally attend those, I got the most benefit from my mentor Omar, who mentored and guided me regularly for years until I finally graduated and moved to SF!",
    },
    {
        key: "fdfghjk",
        src: "http://www.brooklyn.cuny.edu/web/home.php",
        title: "Other Scholarships",
        desc: "Was awarded Harold M. & Carolyn R. Krouse Scholarship, Peter F.Vallone Scholarship, NYC Council Merit, Tell Your Story Scholarship, and multiple Dean's List awards. I believe they were all from my University!",
    },
    {
        key: "rty",
        src: "http://www.brooklyn.cuny.edu/web/home.php",
        title: "SophoMore Academy",
        desc: "Cohort for Brooklyn College Sophomore year students with personal, career, and academic resources and connection to the campus services, and activities. Encourages leadership and proactive engagement with exclusive events and one-on-on academic advisement.",
    },
    {
        key: "fghjk",
        src: "https://joininteract.com/fellowship",
        title: "Interact Fellowship, hopefully soon~",
        desc: "I was told Interact fellowship is where I'd meet the type of people I want to be like: dreamers, creators, technologist brimming with hope and ideas, who want to make the world a better place and are eager for not just dialogue, but also action. I'm applying this year. If you're from the reviewing committee, thanks for stopping by. I'm glad to see the links truly are clicked on and carefully considered. I hope to speak to you soon. Enjoy your visit! There's a few easter eggs hidden here. ",
    },
];

export default affiliations;