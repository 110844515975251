import writing from "./writing.js";
import sillygoose from "./silly-goose.png"

function Writing() {
    return (
        <div className="container">
            <div className="row silly-goose-holder">
                <a href="http://www.phys.unm.edu/~tw/fas/yits/archive/oliver_wildgeese.html"><img src={sillygoose} alt="silly goose" id="goose" /></a>
                <p>
                    I pretty much write all the time. However, I very rarely publish
                    anything. These are some pieces I wrote for the newspaper in
                    college. One day I hope to publish more personal pieces
                    I'm proud of.
                </p>
            </div>
            <div className="row">
                {
                    writing.map(volunteer =>
                        <div key={writing.src}>
                            <h3><a href={volunteer.src}>{volunteer.title}</a></h3>
                            <p>{volunteer.desc}</p>
                            <hr />
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Writing;
