let projects = [
    {
        src: "https://github.com/Shahzzoda/Trippin",
        img: "./img/projects/1.png",
        title: "Trippin",
        desc: "As part of CUNY Tech Prep, I built a full stack web app. From UI Graphic components to google maps api, cloudflare api, database design, api design, and everything in between, I build this MERN stack app!",
    },
    {
        src: "https://devpost.com/software/coffee-run-xme3df",
        img: "./img/projects/2.png",
        title: "Coffee.run",
        desc: "Wrote this during a hackathon. It's like uber but for coffee. It's built on a MERN stack, Twilio API, and caffeine. And some questionable hackathon food that gave my friend food poisoning :^))",
    },
    {
        src: "https://twitter.com/zodattack/status/1335010818602250246",
        img: "./img/projects/3.png",
        title: "Boba Scrapper",
        desc: "Wrote a lil python scraper + web crawler that calculates which borough has the most boba spots. Got a chance to explore the Yelp API and pandas library!",
    },
    {
        src: "https://github.com/siam132/BChack",
        img: "./img/projects/4.png",
        title: "BC Hack Website",
        desc: "This was probably the most collaborative project I have. I built it with the CS club! It's frontend a website, but it holds a special place in my heart as BC's first hackathon, my most ambitious initiative to empower my community, and the beautiful collaboration it took. Graphic components were carefully created by my friend Kevin Tam!",
    },
    {
        src: "https://github.com/Shahzzoda/seasonal-sites",
        img: "./img/projects/5.png",
        title: "Seasonal Sites",
        desc: "I love sending cards and letters to my friends. However, during the pandemic, I was feeling extra expressive and wanted to include photos, quotes, and send them in a surprise link to my friends. Instead of seasonal cards, I built seasonal sites, with my friend Siam! It was a lot of fun and truly a labor of love. I had a lot of fun building intentionally obnoxious (yet charming) websites!",
    },
    {
        src: "https://twitter.com/zodattack/status/1602384974988058624?cxt=HHwWgICxuaOp6LwsAAAA",
        img: "./img/projects/6.png",
        title: "Full Jacket Crochet",
        desc: "\"What if I have a bunch of friends over and buy them all yarn? Could we actually build something?\" is what I thought to myself. Turns out yes. I finger crochet this giant jacket. It only took one night and 1.5 movies. Its my first piece, and I am in love with it.",
    },
    {
        src: "",
        img: "./img/projects/7.png",
        title: "Bookshelf From Scratch",
        desc: "I wish I had better photos of this gigantic bookshelf. It's 8 feet wide, 13 inches deep, and 4 feet tall. I built it in my NYC apartment bedroom while living with my parents during the pandemic and remotely interning at Apple. I picked out the wooden planks, sanded, sawed, painted, cured, and drilled it all on my own. It was a mess but came out so nicely. It was deeply gratifying and increased confidence in my handiwork by 10 folds. It was so much fun, and the biggest home improvement project I've taken on. Took only a few days!",
    },
    {
        src: "",
        img: "./img/projects/8.png",
        title: "Ceramic pieces",
        desc: "I wish I had more concrete pictures to show, but they're still all in development. I love chess and have been playing fairly consistently since coming to SF (and before starting my ceramics class). I really wanted to build my own chess set, and I have been working on it for 7 weeks now (as of Feb 15th). Ceramics has taught me a lot of patience and mindfulness. I'm glad to have picked up a new skill. I'm also building a plate and mug! I'd love to share those photos once they're done. Come back in 4 weeks!",
    },
];

export default projects;