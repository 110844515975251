
import projects from "./projects.js"

function Building() {
    return (
        <div className="container">
            {/* <div className="row"> */}

            <h2 style={{ "textAlign": "center", "margin": "0 auto" }}>Projects!!</h2>
            <p style={{ "textAlign": "center", "margin": "0 auto" }}>
                whether it's through software, wood, yarn, or clay, I really enjoy
                building things. Here are a random moshposh of things I've done.
                Hover over them to read a little about it.
            </p>
            {/* </div> */}
            <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
                </div>
                <div className="carousel-inner">
                    {projects.map((project, i) =>
                        <div className={"carousel-item " + (i == 0 ? "active" : "")} key={project.img}>
                            <img src={project.img} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block overlay">
                                <h5>{project.title}</h5>
                                <p>{project.desc}</p>
                                {console.log(project)}
                            </div>
                        </div>
                    )}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </div>
    );
};

export default Building;
